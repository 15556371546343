.flex-none{
  display: flex;
}

.none-block{
  display: none;
}

.none-flex{
  display: none;
}

.flex-block{
  display: flex;
}

.block-flex{
  display: block;
}

.no-padding{
  
}
.padding-no{
  padding-left: 8px;
}

@media screen and (max-width: 1314px) {
  .flex-none{
    display: none;
  }
  .none-block{
    display: block;
  }
  .none-flex{
    display: flex;
  }
  .flex-block{
    display: block;
  }
  .block-flex{
    display: flex;
  }
  .no-padding{
    padding: 0;
  }
  .padding-no{
    padding-top: 8px !important;
    padding-left: 0px !important;
  }
  .chart-line{
    width: 345px !important;
    height: 424px !important;
  }
  .box-p-pp{
    padding-top: 14px !important;
    padding-left: 64px !important;
    padding-right: 64px !important;
  }
  .hover-p{
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
  .get_started_padding{
    padding-left: 26px !important;
  }
  .general-stats-p{
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .w-mobile{
    width: 184px !important;
  }
  .max-w{
    max-width: 314px !important;
  }
}

.chart-line{
  width: 400px;
  height: 500px;
}

.box-p-pp{
  padding: 14px;
}

.hover-p{
  padding-left: 84px;
  padding-right: 84px;
}

.general-stats-p{
  padding-right: 40px;
  padding-left: 18px;
}

.max-w{
  max-width: 614px;
}

.shake{
  animation: jump-shaking 1.38s infinite;
}

@keyframes jump-shaking{
  0% { transform: translateX(0) }
  25% { transform: translateY(-6px) }
  35% { transform: translateY(-6px) rotate(7deg) }
  55% { transform: translateY(-6px) rotate(-7deg) }
  65% { transform: translateY(-6px) rotate(7deg) }
  75% { transform: translateY(-6px) rotate(-7deg) }
  100% { transform: translateY(0) rotate(0) }
 }